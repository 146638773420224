exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-automation-js": () => import("./../../../src/pages/automation.js" /* webpackChunkName: "component---src-pages-automation-js" */),
  "component---src-pages-contactanos-js": () => import("./../../../src/pages/contactanos.js" /* webpackChunkName: "component---src-pages-contactanos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-noticias-js": () => import("./../../../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-productos-js": () => import("./../../../src/pages/productos.js" /* webpackChunkName: "component---src-pages-productos-js" */),
  "component---src-pages-proyectos-jsx": () => import("./../../../src/pages/proyectos.jsx" /* webpackChunkName: "component---src-pages-proyectos-jsx" */),
  "component---src-pages-termsand-conditions-js": () => import("./../../../src/pages/termsandConditions.js" /* webpackChunkName: "component---src-pages-termsand-conditions-js" */),
  "component---src-templates-contact-page-jsx": () => import("./../../../src/templates/ContactPage.jsx" /* webpackChunkName: "component---src-templates-contact-page-jsx" */),
  "component---src-templates-form-page-jsx": () => import("./../../../src/templates/FormPage.jsx" /* webpackChunkName: "component---src-templates-form-page-jsx" */),
  "component---src-templates-product-page-jsx": () => import("./../../../src/templates/ProductPage.jsx" /* webpackChunkName: "component---src-templates-product-page-jsx" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-promo-page-jsx": () => import("./../../../src/templates/PromoPage.jsx" /* webpackChunkName: "component---src-templates-promo-page-jsx" */)
}

